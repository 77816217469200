
import ContentUtils from '@/utils/ContentUtils.js';

export default class Utils {

  static SortByLast = function(a,b) {
    if (a.last > b.last) {
      return 1;
    }
    if (a.last < b.last) {
      return -1;
    }
    return 0;
  }

  static companyName = function(company) {
    if (!company) {
      return "";
    }
    return ContentUtils.unescape(company.name);
  }

  static companyId = function(company) {
    if (!company) {
      return "";
    }
    return company['@rid']
  }
  
  static address = function(company) {
    return ContentUtils.unescape(company.address)
  }

  static city = function(company) {
    return ContentUtils.unescape(company.city);
  }
  
  static province = function(company) {
    return ContentUtils.unescape(company.jurisdiction);
  }
  
  static country = function(company) {
    return ContentUtils.unescape(company.country);
  }
  
  static postal = function(company) {
    return ContentUtils.unescape(company.postal);
  }
  
  static phoneNumber = function(company) {
    return ContentUtils.unescape(company.phone);
  }

}